import React from "react"
import { Button } from 'react-bootstrap';

import CiForresterStyle from "./ci-forrester.module.scss";

const CiForresterFr = (props) => {

        return         <section className={ CiForresterStyle.forrester }>
        <div className={ CiForresterStyle.inner }>
            <div className={ CiForresterStyle.left }>
                <div className={ CiForresterStyle.content }>
                    <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Forrester.gif" alt=""/>
                    <div className={ CiForresterStyle.text }>
                        Les interactions digitales avec vos clients vont <span>augmenter de 40% en 2021.</span>*
                    </div>
                    <div className={ CiForresterStyle.legend }>
                        *Forrester, Predictions 2021: Customer Service
                    </div>
                </div>
            </div>
            <div className={ CiForresterStyle.right }>
                <div className={ CiForresterStyle.content}>
                    Êtes-vous prêts à leur offrir l’expérience conversationnelle authentique, fluide et permanente qu’ils vivent au quotidien avec leurs proches grâce au messaging ?
                </div>
            </div>
        </div>
    </section>
  
}

export default CiForresterFr



