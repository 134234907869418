import React, { useState } from "react";
import { Toast, Button } from 'react-bootstrap';
import { Link } from 'gatsby';

import CiJoinStyle from "./ci-join.module.scss";

const CiJoinFr = (props) => {


    const [email, setEmail] = useState("");
    const [industrie, setIndustrie] = useState("");
    const [website, setWebsite] = useState("");
    const [job, setJob] = useState("");

    const [showToast, setShowToast] = useState(false);
    const toggleToast = () => setShowToast(!showToast);

    const handleEmail = (event) => setEmail(event.target.value);
    const handleIndustrie = (event) => setIndustrie(event.target.value);
    const handleWebsite = (event) => setWebsite(event.target.value);
    const handleJob = (event) => setJob(event.target.value);

    const sendDataToHubspot = () => {
        console.log("le mail est : " + email);
        console.log("l'industrie est : " + industrie);
        
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"fields":[{"name":"email","value":email},{"name":"consent_checkbox","value":"true"},{"name":"jobtitle","value":job},{"name":"website","value":website},{"name":"ci_industry","value":industrie}],"context":{"pageUri":"www.conversational-index.com/fr/","pageName":"Conversational Index - Landing Page - Form footer"}}),
            redirect: 'follow'
        };
        fetch('https://api.hsforms.com/submissions/v3/integration/submit/403167/1f945562-c2ee-4649-b6ed-c592b3d41b02', requestOptions)
        .then(response => response.json())
        .then(result => console.log(result))
        .then(toggleToast)
        .catch(error => console.log('error', error));
    }


    return         <section className={ CiJoinStyle.join}>
    <img id={ CiJoinStyle.logosBg } src="https://info.iadvize.com/hubfs/CI%20-%202021/Group%20868.gif" alt=""/>

    <div className={ CiJoinStyle.inner}>
        <img id={ CiJoinStyle.logoLight } src="https://info.iadvize.com/hubfs/CI%20-%202021/Subtract%20(1).gif" alt=""/>
        <h2>
            Rejoignez la conversation <span>.</span>
        </h2>
        <div className={ CiJoinStyle.legend}>
            Devenez une marque conversationnelle, et offrez la meilleure expérience client de votre marché. 
        </div>

        <Link className={ CiJoinStyle.ctaTest } to={"questionnaire"}>
          <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Arrow%202.png" alt="" />
          Commencez le test dès maintenant
        </Link>

        <span id={ CiJoinStyle.or }>ou</span>

        <div className={ CiJoinStyle.formInner}>
            <h2>Confiez votre évaluation à nos équipes</h2>

            <form action="" method="get" className={ CiJoinStyle.formLate}>
                <div className={ CiJoinStyle.formSet}>
                    <span>Adresse email*</span>
                    <input type="email" name="email" id="email" placeholder="Email professionnel*" value={email} onChange={handleEmail} required/>
                </div>
                <div className={ CiJoinStyle.formSet}>
                    <span>Profession*</span>
                    <input type="text" name="job" id="job" placeholder="Profession*" value={job} onChange={handleJob} required/>
                </div>
                <div className={ CiJoinStyle.formSet}>
                    <span>Industrie*</span>
                    <select id="industries" name="industries" value={industrie} onChange={handleIndustrie} required>
                        <option value="Retail & CPG">Distribution et produits de grande consommation</option>
                        <option value="Finance - Health Insurance">Finance, santé & Assurance</option>
                        <option value="Automotive">Automobile</option>
                        <option value="Telco & Consumer Services">Télécom & services</option>
                        <option value="Travel & Tourism">Tourisme & Voyages</option>
                        <option value="Other">Autres</option>
                    </select>
                </div>
                <div className={ CiJoinStyle.formSet }>
                    <span>Website*</span>
                    <input type="text" name="website" id="website" placeholder="Site web*" value={website} onChange={handleWebsite} required/>
                </div>

                <div className={ CiJoinStyle.formSet}>
                    <div className={ CiJoinStyle.rgpd }>
                        <input type="checkbox" id="scales" name="scales" required/>
                        <label>J’ai lu et accepte la <a href="https://privacy.iadvize.com/fr/">politique d’iAdvize en matière de protection des données à caractère personnel</a></label>
                    </div>
                </div>

                <div className={ CiJoinStyle.formSet}>
                    <Button id="goToLead" className="ctaFinal" onClick={sendDataToHubspot} disabled={!email || !website || !industrie }>
                        Sollicitez l'évaluation personnalisée
                    </Button>
                </div>
            </form>

        </div>

    </div>

        <div
            style={{
                position: 'fixed',
                top: 10,
                right: 10,
                zIndex: 99
            }}
        >
            <Toast show={showToast} onClose={toggleToast} delay={5000} autohide>
                <Toast.Header>
                    <strong>Merci !</strong>
                </Toast.Header>
                <Toast.Body>Vous allez recevoir un e-mail avec votre secteur ainsi que des axes d'amélioration.</Toast.Body>
            </Toast>

        </div>
</section>
  
}

export default CiJoinFr
