import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';

import CiMenu from "../components/conversational-index/france/ci-menu/ci-menu";
import CiHeaderFr from "../components/conversational-index/france/ci-header/ci-header";
import CiWhyFr from "../components/conversational-index/france/ci-why/ci-why";
import CiFooterFr from "../components/conversational-index/france/ci-footer/ci-footer";
import CiMethodoFr from "../components/conversational-index/france/ci-methodo/ci-methodo";
import CiForresterFr from "../components/conversational-index/france/ci-forrester/ci-forrester";
import CiJoinFr from "../components/conversational-index/france/ci-join/ci-join";
import CiPilliersFr from "../components/conversational-index/france/ci-pilliers/ci-pilliers";
import CiScore from "../components/conversational-index/france/ci-score/ci-score";

import SEO from "../components/seo";


class HomePage extends Component {

    render(){

        return(
            <>
                <SEO title="Conversational Maturity Index : Évaluez la maturité conversationnelle de votre entreprise." image="https://info.iadvize.com/hubfs/CI%20-%202021/Thumbnail%20-%20Conversational%20Index-1.png" lang="fr" description="En réalisant la version en ligne de cet audit, obtenez un aperçu objectif de votre maturité conversationnelle en moins de 10 minutes. Recevez une analyse présentant vos forces et vos axes d’amélioration, ainsi que votre situation par rapport à votre secteur."/>
                <CiMenu/>
                <CiHeaderFr/>
                <CiWhyFr/>
                <CiScore/>
                <CiMethodoFr/>
                <CiPilliersFr/>
                <CiForresterFr/>
                <CiJoinFr/>
                <CiFooterFr/>
            </>
        )
    }

}

export default HomePage