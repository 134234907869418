import React from "react"

import CiPilliersStyle from "./ci-pilliers.module.scss";

const CiPilliersFr = (props) => {

        return         <section className={ CiPilliersStyle.expertise }>
        <div className={ CiPilliersStyle.inner }>
            <div className={ CiPilliersStyle.left }>
                <div className={ CiPilliersStyle.content }>
                    <h3>De manière très fine, l’analyse évalue</h3>
                    <h2>6 piliers</h2>
                </div>
            </div>
            <div className={ CiPilliersStyle.right }>
                <div className={ CiPilliersStyle.line }>
                    <div className={ CiPilliersStyle.bloc }>
                        <div className={ CiPilliersStyle.content }>
                            <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Expertise.png" alt=""/>
                            <div className={ CiPilliersStyle.title }>Expertise, empathie & authenticité</div>
                            <div className={ CiPilliersStyle.texte }>Nous mesurons la qualité de l’expérience offerte dans ces trois dimensions.</div>
                        </div>
                    </div>
                    <div className={[CiPilliersStyle.bloc, CiPilliersStyle.grey].join(' ')}>
                        <div className={ CiPilliersStyle.content }>
                            <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Scalabilite%CC%81.png" alt=""/>
                            <div className={ CiPilliersStyle.title }>Scalabilité</div>
                            <div className={ CiPilliersStyle.texte }>Avez-vous les moyens d’offrir cette expérience à tous vos clients, même lors des pics d’activité ?</div>
                        </div>
                    </div>
                    <div className={ CiPilliersStyle.bloc }>
                        <div className={ CiPilliersStyle.content }>
                            <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Projet%20dentreprise.png" alt=""/>
                            <div className={ CiPilliersStyle.title }>Projet d’entreprise</div>
                            <div className={ CiPilliersStyle.texte }>Votre stratégie conversationnelle est-elle au cœur de votre ADN ?</div>
                        </div>
                    </div>
                </div>
                <div id={ CiPilliersStyle.secondLine } className={ CiPilliersStyle.line }>
                <div className={[CiPilliersStyle.bloc, CiPilliersStyle.grey].join(' ')}>
                        <div className={ CiPilliersStyle.content }>
                            <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Personnalisation.png" alt="" />
                            <div className={ CiPilliersStyle.title }>Engagement et personnalisation</div>
                            <div className={ CiPilliersStyle.texte }>Nous nous intéressons à votre manière d’engager la conversation avec vos clients.</div>
                        </div>
                    </div>
                    <div className={[CiPilliersStyle.bloc, CiPilliersStyle.white].join(' ')}>
                        <div className={ CiPilliersStyle.content }>
                            <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Messaging%20riche.png" alt=""/>
                            <div className={ CiPilliersStyle.title }>Messaging riche et facile à utiliser</div>
                            <div className={ CiPilliersStyle.texte }>Nous observons comment le messaging s’intègre dans votre expérience digitale et mobile. </div>
                        </div>
                    </div>
                    <div className={[CiPilliersStyle.bloc, CiPilliersStyle.grey].join(' ')}>
                        <div className={ CiPilliersStyle.content }>
                            <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Omnicanal.png" alt=""/>
                            <div className={ CiPilliersStyle.title }>Fluidité & omnicanalité</div>
                            <div className={ CiPilliersStyle.texte }>Proposez-vous un lien continu à vos clients via messaging, au-delà  de votre site ?</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  
}

export default CiPilliersFr
