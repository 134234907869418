import React from "react"

import { Link } from 'gatsby';

import CiHeaderStyle from "./ci-header.module.scss";

const CiHeaderFr = (props) => {

    return <section className={ CiHeaderStyle.header }>
    <div className={ CiHeaderStyle.inner }>
      <div className={ CiHeaderStyle.left }>
        <h1>
          <div id={ CiHeaderStyle.word }>Évaluez</div>votre maturité conversationnelle<span>.</span>
        </h1>
        <div className={ CiHeaderStyle.legend }>
          À travers 6 piliers, mesurez-vous aux leaders de votre secteur, et rejoignez une transformation vitale pour les entreprises.
        </div>
        
        <Link to={"questionnaire"}>
          <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Arrow%202.png" alt="" />
          Démarrer l’évaluation
        </Link>

      </div>
      <div className={ CiHeaderStyle.right }>
        <div id={ CiHeaderStyle.star } className={ CiHeaderStyle.star }>
          <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Frame%2011986%20(1).png"></img>
        </div>
        <div id={ CiHeaderStyle.score } className={ CiHeaderStyle.score }>
          <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Score%20(1).png"></img>
        </div>
        <div id={ CiHeaderStyle.ticks } className={ CiHeaderStyle.ticks }>
          <div className={ CiHeaderStyle.tick }>
            <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Vector%201.png" alt="" />
            <span>Réalisez le test</span>
          </div>
          <div className={ CiHeaderStyle.tick }>
            <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Vector%201.png" alt="" />
            <span>Obtenez votre score</span>
          </div>
          <div className={ CiHeaderStyle.tick }>
            <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Vector%201.png" alt="" />
            <span>Identifiez vos axes d’amélioration</span>
          </div>
        </div>
      </div>
      <div className={ CiHeaderStyle.assurance }>
        <div className={ CiHeaderStyle.bloc }>
          <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Group.png" alt="" />
          <h3>Une méthodologie basée sur 10 ans d’expertise</h3>
          <div className={ CiHeaderStyle.content }>En travaillant avec les plus grandes marques comme Samsung ou OtterBox, iAdvize a développé une méthodologie unique pour analyser leur stratégie digitale, traduite dans le Conversational Maturity Index.</div>
        </div>
        <div className={ CiHeaderStyle.bloc }>
          <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Vector%2029.png" alt="" />
          <h3>Une expérience interactive et instantanée</h3>
          <div className={ CiHeaderStyle.content }>En réalisant la version en ligne de cet audit, composée de 19 questions simples, obtenez un aperçu objectif de votre maturité conversationnelle en moins de 10 minutes.</div>
        </div>
        <div className={ CiHeaderStyle.bloc }>
          <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Group-1.png" alt="" />
          <h3>Une évaluation précise de votre situation</h3>
          <div className={ CiHeaderStyle.content }>Avec votre score, recevez une analyse présentant vos forces et vos axes d’amélioration sur 6 piliers, ainsi que votre situation par rapport à votre secteur.</div>
        </div>
      </div>
    </div>
  </section>
  
}

export default CiHeaderFr
