import React from "react";

import { Tab, Row, Nav, Col, Sonnet, Container } from 'react-bootstrap';

import CiScoreStyle from "./ci-score.module.scss";

import "./custom.scss";

const CiScoreFr = (props) => {

    return <section className={ CiScoreStyle.score }>
            <div className={ CiScoreStyle.inner }>

                <div className={ CiScoreStyle.title }>
                    <h2>
                        <span>Obtenez</span>
                        votre score dès maintenant
                    </h2>

                    <div className={ CiScoreStyle.legend }>
                        <strong>En 10 minutes,</strong> soumettez votre marque à une série de points d’évaluation, et recevez un score représentant votre niveau de maturité ainsi que votre résultat détaillé. 
                    </div>
                </div>

                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Row>

                        <Col>
                            <Tab.Content className="custom-nav-bg">
                                <Tab.Pane eventKey="first">
                                    <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Score.png"></img>        
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Score-1.png"></img>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Score-2.png"></img>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>

                        <Col>
                            <Nav className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="first" className="button">
                                        <div className="icon" id="btn1"></div>
                                        <h2>Vue sectorielle</h2>
                                        <div className="text">Votre situation par rapport aux leaders de votre secteur</div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second" className="button">
                                        <div className="icon" id="btn2"></div>
                                        <h2>Points forts</h2>
                                        <div className="text">Un récapitulatif des points forts de votre expérience digitale</div>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item> 
                                    <Nav.Link eventKey="third" className="button">
                                        <div className="icon" id="btn3"></div>
                                        <h2>Axes d’amélioration</h2>
                                        <div className="text">Les solutions pour gagner en maturité conversationnelle sur 6 piliers</div>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>

                    </Row>
                </Tab.Container>

            </div>
        </section>

}

export default CiScoreFr

