import React from "react"

import CiWhyStyle from "./ci-why.module.scss";

const CiWhyFr = (props) => {

    return <section id={ CiWhyStyle.whySection } className={ CiWhyStyle.why }>
            <div className={ CiWhyStyle.inner }>

                <div className={ CiWhyStyle.left}>
                <div className={ CiWhyStyle.content}>
                    <img id={ CiWhyStyle.logo} src="https://info.iadvize.com/hubfs/CI%20-%202021/Logo%20CI.png" alt=""/>
                    <div className={ CiWhyStyle.preview }>
                        <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Capture%20d%E2%80%99e%CC%81cran%202021-03-11%20a%CC%80%2008.27.52.png"></img>
                    </div>
                </div>
                </div>

                <div className={ CiWhyStyle.right}>
                    <div className={ CiWhyStyle.top}>
                        <h2>Identifiez</h2>
                        <h3>les priorités pour élever votre expérience digitale</h3>
                    </div>
                    <div className={ CiWhyStyle.bottom}>
                        <h3>
                            Une évaluation précise et objective
                        </h3>
                        <div className={ CiWhyStyle.content }>
                            Le Conversational Maturity Index est basé sur une méthodologie conçue et validée par <a href="https://wwww.iadvize.com/fr/">iAdvize</a> associant des questions fermées et une analyse qualitative.<br/><br/>
                            En fonction d’une évaluation précise et objective, il permet aux marques d’évaluer leur maturité conversationnelle et d’identifier les enjeux-clés pour élever leur expérience digitale.
                        </div>
                    </div>
                </div>

            </div>
        </section>
  
}

export default CiWhyFr